import * as React from "react"
import styled from 'styled-components'
import Menu from '../components/menu'
import Footer from '../components/footer'
import FooterBanner from '../components/footer-banner'
import SubPageHero from '../components/sub-page-hero'
import HelmetInfo from '../components/helmet-info'
import '../styles/typography.css'
import { Link } from "gatsby"
import squiggles from '../images/squiggles.svg'
import shortsIcon from '../images/shorts-icon.svg'
import explainerIcon from '../images/explainer-icon.svg'
import educationalIcon from '../images/educational-icon.svg'
import commercialIcon from '../images/commercial-icon.svg'
import 'animate.css'


// Styled Components
const AnimationServicesWrapper = styled.div`
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    background: white;
    padding-bottom: 20px;
`

const AnimationServicesGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 20px; 
    margin-bottom: 60px;
    margin-top: 40px;

    @media screen and (min-width: 600px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: 900px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }


    .icon {
      margin: 0 auto;
      background: white;
      margin-bottom: 20px;
    }

    img {
        max-width: 146px;
        width: 40%!important;
        display: block;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        border-radius: 100%;
    }

    .icon {
      max-width: 146px;
      margin: 0 auto;
      display: block;
    }

    a:hover {
      box-shadow: 0 0 10px rgba(0,0,0,0.1);
      border-radius: 10px;
    }

    a:hover .icon {
      transform: rotate(360deg) scale(1.1);
      transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    h3 {
      font-family: 'Zing';
      font-size: 26px;
      margin-top: 10px;
      margin-bottom: 10px;
      line-height: 1.1;
      display: table;
      color: black;
    }
`

const Service = ({ 
    imageAlt, 
    imageSrc, 
    serviceTitle, 
    serviceLink,
    serviceText
  }) => {

  return(

      <Link to={serviceLink}>
          <img className='icon' src={imageSrc} alt={imageAlt} />
          <h3>{serviceTitle}</h3>
          <p>{serviceText}</p>
      </Link>
  )
}

const AnimationServices = () => {
  return (
    <>
    <HelmetInfo />
    <Menu/>
    <main>
        <SubPageHero>
            <h1 className="animate__animated animate__bounceInDown">Animation Services</h1>
            <img className="hero-squiggles" src={squiggles} alt="decorative squiggles" />
        </SubPageHero>

        <AnimationServicesWrapper>
            <h2>What we can do at FizzPack</h2>
            <p>Use video and animation to better your business. We believe video should be part of every strong marketing campaign.</p>

            <AnimationServicesGrid>
              <Service 
                serviceLink={'./shorts'}
                imageAlt={'Shorts and Social icon'} 
                imageSrc={shortsIcon} 
                serviceTitle={'Shorts & Social'} 
                serviceText={'We like short shorts. Social media digestable clips to boost sales'}
              />

              <Service 
                serviceLink={'./explainer'}
                imageAlt={'Explainer Videos icon'} 
                imageSrc={explainerIcon} 
                serviceTitle={'Explainer Videos'} 
                serviceText={'Explain your product to your customers in just 2 minutes'}
              />

              <Service 
                serviceLink={'./educational'}
                imageAlt={'Educational Video icon'} 
                imageSrc={educationalIcon} 
                serviceTitle={'Educational Videos'} 
                serviceText={'Share your knowledge with the world with useful video content'}
              />

<             Service 
                serviceLink={'./commercial'}
                imageAlt={'Commerical Video icon'} 
                imageSrc={commercialIcon} 
                serviceTitle={'Commercial Video'} 
                serviceText={'Promote your company with a tried and tested video commercial'}
              />

            </AnimationServicesGrid>
            <p>Fizzpack is an animated video production company! We specialize in animated explainer videos, demo videos, corporate videos and marketing videos for your business. We produce video that makes the biggest impact on instagram, facebook, snapchat, youtube and other social platforms.</p>
        </AnimationServicesWrapper>

        <FooterBanner title="Grow Your Business: Video Ads That Convert" linkUrl="/contact-us" linkText="Contact Us"/>
    </main>
    <Footer />
    </>
  )
}

export default AnimationServices
